/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TopContent from '../../Shared/Components/TopContent';
import DropDown from '../../Shared/Components/DropDown';
import {
    fetchLicenses,
    setTableFilterData,
    setFilter,
} from '../../redux/slices/LicensesSlice';
import DeleteModal from '../../Shared/Components/DeleteModal';
import more from '../../assets/icons/more.png';
import DataTable from '../Table';
import exportCSV from '../Global/exportCSV';
import licensesApi from '../../utils/api/licenses';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { checkPermissionAllow } from '../../utils/helpers';
import height from '../../utils/size-variables';
import ModalBox from '../../Shared/Components/ModalBox';
import LicenseAdd from '../License/Components/LicenseAdd';
import LicenseView from './Components/LicenseView';

function License() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        licenses,
        pageCount,
        totalPageCount,
        status,
        filter,
        tableFilterData,
        totalDataCount,
    } = useSelector((state) => state.licenses);

    const statusList = [
        { value: 'pending', label: t('inActive') },
        { value: 'payed', label: t('active') },
    ];
    const [open, setOpen] = useState(false);
    const [modelView, setModelView] = useState({});

    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = useState({});

    const [isFirstRender, setIsFirstRender] = useState(true);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [notificationPopup, setNotificationPopup] = useState(false);


    const getLicenses = (counts, search) => {
        dispatch(fetchLicenses({ page: counts, ...search }));
    };

    const handleScroll = () => {
        getLicenses(pageCount, filter);
    };

    const deleteUser = () => { };

    const viewFunction = (singleSubscription) => {
        setOpen(true);
        setModelView({
            title: t('License Summary'),
            content: <LicenseView
                data={singleSubscription}
            />,
        });
    };
    const getDeleteUserId = (id) => {
        // setOpenDeleteModal(true);
        // setDeleteId(id);
    };

    const actions = [
        // {
        //   name: 'Edit',
        //   url: '',
        //   function: () => {},
        //   key: 'edit',
        // },
        {
            name: t('view'),
            url: '',
            function: viewFunction,
            key: 'view',
            enable: checkPermissionAllow('getPlan'),
        },
        // {
        //     name: 'Delete',
        //     url: '',
        //     //   function: getDeleteUserId,
        //     key: 'delete',
        //     enable: checkPermissionAllow('getPlan'),
        // },
    ];

    // const clickFunction = () => {
    //     navigate('license-add');
    // };

    const columns = [
        // {
        //   header: t('Sno'),
        //   accessorKey: 'sno',
        // },
        {
            header: t('account'),
            accessorKey: 'company_name',
        },
        {
            header: t('licenseType'),
            accessorKey: 'license_type',
            Cell: ({ row }) => t(row.original.license_type),
        },
        {
            header: t('planName'),
            accessorKey: 'license_plan_name',
        },
        {
            header: t('tenure'),
            accessorKey: 'tenure',
            Cell: ({ row }) => row.original.tenure === 0 ? 'Unlimited' : row.original.tenure,
        },     
        {
            header: t('totalDisplays'),
            accessorKey: 'device_count',
            enableColumnFilter: false,
        },
        {
            header: t('generatedDate'),
            accessorKey: 'created_at',
            enableColumnFilter: false,
        },
        {
            header: t('licenseStatus'),
            accessorKey: 'status',
            filterVariant: 'select',
            filterSelectOptions: statusList,
            Cell: ({ row }) => {
                const originalStatus = row.original.initial_payment_status;
                let paymentStatus;

                if (originalStatus === 'pending') {
                    paymentStatus = <span className="payment-pending">{t('Inactive')}</span>;
                } else if (originalStatus === 'completed') {
                    paymentStatus = <span className="payment-updated">{t('Active')}</span>;
                }
                return paymentStatus;
            },
        },
        {
            header: '',
            accessorKey: 'id',
            enableColumnActions: false,
            enableColumnFilter: false,
            enableSorting: false,
            enableResizing: false,
            enableHiding: true,
            filterVariant: 'none',
            size: 50,
            Cell: ({ row }) => (
                <DropDown
                    action={actions}
                    icon={more}
                    value={row?.original}
                    dropdown="tableForm"
                    height="15px"
                    tooltip=""
                />
            ),
        },
    ];

    const exportData = () => {
        const columnHeaders = ['name', 'plan_type', 'product'];

        // Filter the hidden column
        const filteredHeaders = columnHeaders.filter((header) => columnVisibility[header] !== false);

        // API call for export CSV
        licensesApi.exportLicensesData({ fields: filteredHeaders, ...filter })
            .then((res) => {
                // Function to export as CSV file
                exportCSV(Object.keys(res?.data?.[0]), res?.data);
            })
            .catch((err) => {
                dispatch(setErrorNotification(err?.response?.data));
            });
    };

    const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
        dispatch(setTableFilterData(reduxColumnFiltersSorting));
        dispatch(setFilter(filteredValues));
        getLicenses(1, filteredValues);
    };
    const closeModal = () => {
        setOpen(false);
    };

    const clickFunction = () => {
        setOpen(true);
        setModelView({
            title: t('newLicense'),
            content: <LicenseAdd back={closeModal} />,
        });
    };
    useEffect(() => {
        setIsFirstRender(false);
        if (pageCount === 0 && Object.keys(tableFilterData).length === 0) {
            getLicenses(1, {});
        }
    }, []);

    return (
        <div className="main-container">
            <TopContent
                label={t('licenses')}
                buttonClass="success-button"
                button={checkPermissionAllow('addPlan')}
                buttonLabel={t('newLicense')}
                click={clickFunction}
            />
            <ModalBox
                status={open}
                closeModal={closeModal}
                modalView={modelView}
                notification={notificationPopup}
            />
            <DeleteModal
                status={openDeleteModal}
                closeModal={setOpenDeleteModal}
                okFunction={deleteUser}
                buttonLabel="Delete"
                title="Are you sure you want to delete this User?"
                subTitle="This will delete this user permanently. You cannot undo this action."
            />
            <div className="content-area">
                <div className="my-content">
                    <div className="my-content-area">
                        <DataTable
                            header={columns}
                            value={licenses}
                            status={status}
                            totalPageCount={totalPageCount}
                            pageCount={pageCount}
                            scrollData={handleScroll}
                            reduxColumnFiltersSorting={tableFilterData}
                            onFilterOrSortingChange={onFilterOrSortingChange}
                            isFirstRender={isFirstRender}
                            totalDataCount={totalDataCount}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                            columnVisibility={columnVisibility}
                            setColumnVisibility={setColumnVisibility}
                            enable={Object.keys(filter).filter((val) => val !== 'sortField' && val !== 'sortBy').length > 0}
                            height={height.tableHeight}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
License.propTypes = {
    row: PropTypes.shape({
        original: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

License.defaultProps = {
    row: null,
};

export default License;
