import { configureStore } from '@reduxjs/toolkit';
import notificationReducer from './slices/NotificationSlice';
import rolesReducer from './slices/RolesSlice';
import companyReducer from './slices/CompanySlice';
import indirectCompanyReducer from './slices/IndirectCompanySlice';
import distributorReducer from './slices/DistributorSlice';
import usersReducer from './slices/UsersSlice';
import storeReducer from './slices/StoreLocationSlice';
import activeSubscriptionReducer from './slices/ActiveSubscriptionSlice';
import pendingSubscriptionReducer from './slices/PendingSubscriptionSlice';
import upcomingSubscriptionReducer from './slices/UpcomingSubscriptionSlice';
import expiredSubscriptionReducer from './slices/ExpiredSubscriptionSlice';
import deviceCatalogueReducer from './slices/DeviceCatalogueSlice';
import plansReducer from './slices/PlanSlice';
import addonReducer from './slices/AddonSlice';
import featureControllerReducer from './slices/FeatureControllerSlice';
import transactionSummaryReducer from './slices/TransactionSummarySlice';
import transactionPendingReducer from './slices/TransactionPendingSlice';
import deviceOrderProcessReducer from './slices/DeviceOrderProcessSlice';
import deviceOrderPendingReducer from './slices/DeviceOrderPendingSlice';
import deviceOrderFulfillReducer from './slices/DeviceOrderFulfillSlice';
import deviceOrderActiveReducer from './slices/DeviceOrderActiveSlice';
import deviceOrderExpiredReducer from './slices/DeviceOrderExpiredSlice';
import contactReducer from './slices/ContactSlice';
import playerAppReducer from './slices/PlayerAppSlice';
import saasPendingReducer from './slices/SaasPendingSlice';
import rentalPendingReducer from './slices/RentalPendingSlice';
import saasRentalPendingReducer from './slices/SaasRentalPendingSlice';
import licensesPlansReducer from './slices/LicensesPlanSlice';
import licensesReducer from './slices/LicensesSlice';
import licensesPendingReducer from './slices/LicensesPendingSlice';
import licensesActiveReducer from './slices/LicensesActiveSlice';
import licenseTransactionSummary from './slices/LicenseTransactionSummarySlice';
import cmsServerReducer from './slices/ServerSlice';
import licenseFeatureControllerReducer from './slices/LicenseFeatureControllerSlice';
import inDirectServer from './slices/IndirectserverCompanySlice';

const store = configureStore({
  reducer: {
    notification: notificationReducer,
    roles: rolesReducer,
    store: storeReducer,
    company: companyReducer,
    indirectCompany: indirectCompanyReducer,
    distributor: distributorReducer,
    users: usersReducer,
    activeSubscription: activeSubscriptionReducer,
    pendingSubscription: pendingSubscriptionReducer,
    upcomingSubscription: upcomingSubscriptionReducer,
    expiredSubscription: expiredSubscriptionReducer,
    deviceCatalogue: deviceCatalogueReducer,
    plans: plansReducer,
    addon: addonReducer,
    feature: featureControllerReducer,
    transactionSummary: transactionSummaryReducer,
    transactionPending: transactionPendingReducer,
    deviceOrderProcess: deviceOrderProcessReducer,
    deviceOrderPending: deviceOrderPendingReducer,
    deviceOrderFulfill: deviceOrderFulfillReducer,
    deviceOrderActive: deviceOrderActiveReducer,
    deviceOrderExpired: deviceOrderExpiredReducer,
    contacts: contactReducer,
    playerAppVersion: playerAppReducer,
    saasPending: saasPendingReducer,
    rentalPending: rentalPendingReducer,
    saasRentalPending: saasRentalPendingReducer,
    licensesPlans: licensesPlansReducer,
    licenses: licensesReducer,
    licensesPending: licensesPendingReducer,
    licensesActive: licensesActiveReducer,
    licenseTransactionSummary,
    cmsServer: cmsServerReducer,
    licenseFeature: licenseFeatureControllerReducer,
    inDirectServer,
  },
});

export default store;
