/*eslint-disable*/
import React, { useRef, useEffect, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
} from 'material-react-table';
import { Typography } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  QueryClient,
  QueryClientProvider,
  // useInfiniteQuery,
} from '@tanstack/react-query';
import Button from '../../Shared/Components/Button';
import ExportButton from './exportBtn';

function Table(props) {
  const {
    header,
    status,
    pageCount,
    totalPageCount,
    value,
    scrollData,
    totalDataCount,
    disableFilter,
    enableRowSelection,
    rowSelection,
    setRowSelection,
    setGlobalFilter,
    disableSorting,
    globalFilter,
    isFirstRender,
    reduxColumnFiltersSorting,
    onFilterOrSortingChange,
    enable,
    height,
    disableFullScreenToggle,
    enableRowOrdering,
    setValue,
    columnVisibility,
    setColumnVisibility,
    exportButton,
    exportData,
    disableFilterButton,
    disableShowHideButton,
    disableToggleButton,
    disableFullScreen,
  } = props?.data || {};

  const tableContainerRef = useRef(null);
  const rowVirtualizerInstanceRef = useRef(null);

  const [columnFilters, setColumnFilters] = useState(reduxColumnFiltersSorting?.columnFilters || []);
  const [sorting, setSorting] = useState(reduxColumnFiltersSorting?.sorting || []);

  const fetchMoreOnBottomReached = (containerRefElement) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      if (
        scrollHeight - scrollTop - clientHeight < 20
        && status !== 'loading'
        && pageCount < totalPageCount
        && totalPageCount > 1
      ) {
        scrollData();
      }
    }
  };

  useEffect(() => {
    if (!isFirstRender) {
      // Data readable by Mui table
      const reduxColumnFiltersSortingLocal = {
        columnFilters,
        sorting,
      };
      // Filter to send with API
      let filteredValues = {};

      // When content sorting applied
      if (sorting.length > 0) {
        filteredValues = {
          ...filteredValues,
          sortField: sorting[0].id,
          sortBy: sorting[0].desc ? 'desc' : 'asc',
        };
      }

      // When content column filters applied
      if (columnFilters.length > 0) {
        // Create a key: value pair from the array of filter object
        const mergedColumnFilterObject = columnFilters.reduce((acc, curr) => {
          if (curr.value) {
            acc[curr.id] = curr.value;
          }
          return acc;
        }, {});
        filteredValues = {
          ...filteredValues,
          ...mergedColumnFilterObject,
        };
      }

      onFilterOrSortingChange(filteredValues, reduxColumnFiltersSortingLocal);
    }
  }, [columnFilters, sorting]);

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const tableProps = useMaterialReactTable({
    enableFilters: disableFilter && false,
    enableSorting: disableSorting && false,
    enableFullScreenToggle: disableFullScreenToggle && false,
    enableColumnResizing: true,
    layoutMode: 'grid',
    columns: header,
    data: value,
    enablePagination: false,
    enableRowNumbers: false,
    enableRowSelection,
    enableRowOrdering,
    enableRowVirtualization: true,
    manualFiltering: false,
    manualSorting: true,
    enableGlobalFilter: false,
    initialState: { showColumnFilters: enable, showGlobalFilter: false },
    enableSortingRemoval: false,
    onColumnVisibilityChange: setColumnVisibility,
    enableColumnPinning: true,
    initialState: { columnPinning: { right: ['action'] } },
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: { maxHeight: height },
      onScroll: (event) => fetchMoreOnBottomReached(event.target),
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onSortingChange: setSorting,
    muiLinearProgressProps: {
      color: 'success',
    },
    renderBottomToolbarCustomActions: () => (
      <Typography>
        Fetched {value?.length} of {totalDataCount}.
      </Typography>
    ),
    // muiTableBodyRowProps: ({ row }) => ({
    //   onClick: row.getToggleSelectedHandler(),
    //   sx: { cursor: 'pointer' },
    // }),
    muiSelectCheckboxProps: {
      color: 'warning',
    },
    filterFns: {
      customFilterFn: (filterValue) => {
        let val;
        if (filterValue[0] && filterValue[1]) {
          val = true;
        } else {
          val = false;
        }
        return val;
      },
      commonFIlterFn: () => true,
    },
    state: {
      columnVisibility,
      columnFilters,
      globalFilter,
      rowSelection,
      // isLoading,
      showProgressBars: status === 'loading',
      sorting,
    },
    rowVirtualizerInstanceRef,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          value.splice(
            hoveredRow.index,
            0,
            value.splice(draggingRow.index, 1)[0],
          );
          setValue([...value]);
        }
      },
    }),
    rowVirtualizerOptions: { overscan: 4 },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content': {
          justifyContent: 'space-between',
        },
      },
    },
    // customize built-in buttons in the top-right of top toolbar
    renderToolbarInternalActions: ({ table }) => (
      <div className="table-header-right">
        {exportButton && value.length > 0
          && (
            <div className="csv-button-wrap">
              <Button
                label={<ExportButton />}
                classes="export-button"
                click={exportData}
              />
            </div>
          )}
        {/* along-side built-in buttons in whatever order you want them */}
        {
          !disableFilterButton && <MRT_ToggleFiltersButton table={table} />
        }
        {
          !disableShowHideButton && <MRT_ShowHideColumnsButton table={table} />
        }
        {
          !disableToggleButton && <MRT_ToggleDensePaddingButton table={table} />
        }
        {
          !disableFullScreen && <MRT_ToggleFullScreenButton table={table} />
        }
      </div>
    ),
  });

  return <MaterialReactTable table={tableProps} />;
}

const queryClient = new QueryClient();

function DataTable(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props} />
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export default DataTable;
